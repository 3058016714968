import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VDatePicker } from 'vuetify/lib/components/VDatePicker';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VForm } from 'vuetify/lib/components/VForm';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VToolbar } from 'vuetify/lib/components/VToolbar';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VDialog,{attrs:{"max-width":"700px"},on:{"click:outside":_vm.cancelMethod},model:{value:(_vm.show),callback:function ($$v) {_vm.show=$$v},expression:"show"}},[_c(VCard,[_c(VToolbar,{staticClass:"text-h5",attrs:{"color":"primary","dark":""}},[_vm._v(_vm._s(_vm.formTitle)+" "),_c(VSpacer),_c(VBtn,{attrs:{"icon":""},on:{"click":_vm.cancelMethod}},[_c(VIcon,[_vm._v("mdi-close")])],1)],1),_c(VCardText,[_c(VForm,{ref:"form"},[_c(VContainer,{staticClass:"mt-4"},[_c(VRow,{staticClass:"d-flex flex-row align-center justify-space-between"},[_c(VCol,[_c(VMenu,{ref:"menu",attrs:{"close-on-content-click":true,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VTextField,_vm._g(_vm._b({attrs:{"label":"Anno","readonly":"","disabled":_vm.addingDate},model:{value:(_vm.displayYearPicker),callback:function ($$v) {_vm.displayYearPicker=$$v},expression:"displayYearPicker"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.yearPickerMenu),callback:function ($$v) {_vm.yearPickerMenu=$$v},expression:"yearPickerMenu"}},[_c(VDatePicker,{attrs:{"no-title":"","active-picker":_vm.activePicker,"max":new Date(Date.UTC(_vm.currentYear + 20, 0, 1)).toISOString(),"min":new Date(Date.UTC(_vm.currentYear - 20, 0, 1)).toISOString()},on:{"update:activePicker":function($event){_vm.activePicker=$event},"update:active-picker":function($event){_vm.activePicker=$event},"click:year":_vm.saveYear},model:{value:(_vm.yearPicker),callback:function ($$v) {_vm.yearPicker=$$v},expression:"yearPicker"}})],1)],1),_c(VCol,{staticClass:"d-flex justify-end"},[_c(VBtn,{attrs:{"outlined":"","disabled":!_vm.yearPicker || _vm.addingDate},on:{"click":_vm.setDefaultDates}},[_vm._v(" Carica Default ")])],1)],1),_c(VRow,[_c(VContainer,{staticStyle:{"overflow-y":"auto","max-height":"300px"}},[(!_vm.addingDate)?_c('div',[_c(VDataTable,{attrs:{"headers":_vm.holidayHeaders,"items":_vm.displayUpdatedHolidayDates,"disable-pagination":"","disable-filtering":"","hide-default-footer":"","fixed-header":""},scopedSlots:_vm._u([{key:"item.actions",fn:function(ref){
var index = ref.index;
return [_c(VIcon,{attrs:{"small":""},on:{"click":function($event){return _vm.editDate(index)}}},[_vm._v(" mdi-pencil ")]),_c(VIcon,{staticClass:"ml-2",attrs:{"small":""},on:{"click":function($event){return _vm.updatedHolidayDates.splice(index, 1)}}},[_vm._v(" mdi-delete ")])]}}],null,false,1981350966)}),_c(VDivider),_c(VBtn,{staticClass:"mt-4",attrs:{"small":""},on:{"click":function($event){_vm.addingDate = true}}},[_c(VIcon,{staticClass:"mr-2"},[_vm._v(" mdi-plus ")]),_vm._v(" Aggiungi Data ")],1)],1):_c('div',{staticClass:"d-flex flex-column"},[_c('div',{staticClass:"d-flex flex-row justify-space-around align-center"},[_c('span',[_c(VMenu,{ref:"addingMenu",attrs:{"close-on-content-click":true,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VTextField,_vm._g(_vm._b({attrs:{"label":"Data","readonly":""},model:{value:(_vm.displayAddingDateValue),callback:function ($$v) {_vm.displayAddingDateValue=$$v},expression:"displayAddingDateValue"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.addingDateMenu),callback:function ($$v) {_vm.addingDateMenu=$$v},expression:"addingDateMenu"}},[_c(VDatePicker,{attrs:{"no-title":"","max":new Date(Date.UTC(_vm.currentYear, 11, 31)).toISOString(),"min":new Date(Date.UTC(_vm.currentYear, 0, 1)).toISOString(),"locale":"it"},model:{value:(_vm.addingDateValue),callback:function ($$v) {_vm.addingDateValue=$$v},expression:"addingDateValue"}})],1)],1),_c('span',[_c(VTextField,{attrs:{"label":"Nome"},model:{value:(_vm.addingDateName),callback:function ($$v) {_vm.addingDateName=$$v},expression:"addingDateName"}})],1),_c(VBtn,{staticClass:"ml-4",attrs:{"small":""},on:{"click":_vm.cancelAddingHolidayDate}},[_vm._v(" Annulla ")]),_c(VBtn,{staticClass:"ml-2",attrs:{"small":"","disabled":!_vm.addingDateValue || !_vm.addingDateName.trim()},on:{"click":_vm.addHolidayDate}},[_vm._v(" Inserisci ")])],1)])])],1)],1)],1)],1),_c(VCardActions,[_c(VSpacer),_c(VBtn,{staticClass:"px-4",attrs:{"color":"green accent-2","disabled":_vm.addingDate || !_vm.yearPicker},on:{"click":_vm.saveMethod}},[_c(VIcon,{attrs:{"left":""}},[_vm._v(" mdi-check ")]),_vm._v(" Salva ")],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }