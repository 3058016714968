<template>
    <v-dialog
        v-model="show"
        max-width="700px"
        v-on:click:outside="cancelMethod"
    >
        <v-card>
            <v-toolbar
                color="primary"
                dark
                class="text-h5"
            >{{formTitle}}
                <v-spacer></v-spacer>
                <v-btn 
                    icon
                    @click="cancelMethod"
                >
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-toolbar>

            <v-card-text>
                <v-form ref="form">
                    <v-container class="mt-4">
                        <v-row class="d-flex flex-row align-center justify-space-between">
                            <v-col>
                                <v-menu
                                    ref="menu"
                                    v-model="yearPickerMenu"
                                    :close-on-content-click="true"
                                    transition="scale-transition"
                                    offset-y
                                    min-width="auto"
                                >
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field
                                            v-model="displayYearPicker"
                                            label="Anno"
                                            readonly
                                            v-bind="attrs"
                                            v-on="on"
                                            :disabled="addingDate"
                                        ></v-text-field>
                                    </template>
                                    <v-date-picker
                                        no-title
                                        v-model="yearPicker"
                                        :active-picker.sync="activePicker"
                                        @click:year="saveYear"
                                        :max="new Date(Date.UTC(currentYear + 20, 0, 1)).toISOString()"
                                        :min="new Date(Date.UTC(currentYear - 20, 0, 1)).toISOString()"
                                    ></v-date-picker>
                                </v-menu>
                            </v-col>
                            <v-col class="d-flex justify-end">
                                <v-btn
                                    outlined
                                    @click="setDefaultDates"
                                    :disabled="!yearPicker || addingDate"
                                >
                                    Carica Default
                                </v-btn>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-container style="overflow-y: auto; max-height: 300px;">
                                <div v-if="!addingDate">
                                    <v-data-table
                                        :headers="holidayHeaders"
                                        :items="displayUpdatedHolidayDates"
                                        disable-pagination
                                        disable-filtering
                                        hide-default-footer
                                        fixed-header
                                    >
                                        <template v-slot:item.actions="{ index }">
                                            <v-icon
                                                small
                                                @click="editDate(index)"
                                            >
                                                mdi-pencil
                                            </v-icon>
                                            <v-icon
                                                small
                                                @click="updatedHolidayDates.splice(index, 1)"
                                                class="ml-2"
                                            >
                                                mdi-delete
                                            </v-icon>
                                        </template>
                                        <!-- <template v-slot:body.append="{ }" v-if="addingDate">
                                            <tr>
                                                <td>
                                                </td>
                                                <td>
                                                </td>
                                                <td>
                                                    <v-btn>
                                                        inserisci
                                                    </v-btn>
                                                </td>
                                            </tr>
                                        </template> -->
                                    </v-data-table>
                                    <v-divider />
                                    <v-btn @click="addingDate = true" class="mt-4" small>
                                        <v-icon class="mr-2">
                                            mdi-plus
                                        </v-icon>
                                        Aggiungi Data
                                    </v-btn>
                                </div>
                                <div v-else class="d-flex flex-column">
                                    <div class="d-flex flex-row justify-space-around align-center">
                                         <span>
                                            <v-menu
                                                ref="addingMenu"
                                                v-model="addingDateMenu"
                                                :close-on-content-click="true"
                                                transition="scale-transition"
                                                offset-y
                                                min-width="auto"
                                            >
                                                <template v-slot:activator="{ on, attrs }">
                                                    <v-text-field
                                                        v-model="displayAddingDateValue"
                                                        label="Data"
                                                        readonly
                                                        v-bind="attrs"
                                                        v-on="on"
                                                    ></v-text-field>
                                                </template>
                                                <v-date-picker
                                                    no-title
                                                    v-model="addingDateValue"
                                                    :max="new Date(Date.UTC(currentYear, 11, 31)).toISOString()"
                                                    :min="new Date(Date.UTC(currentYear, 0, 1)).toISOString()"
                                                    locale="it"
                                                ></v-date-picker>
                                            </v-menu>
                                        </span>
                                        <span>
                                            <v-text-field
                                                v-model="addingDateName"
                                                label="Nome"
                                            >
                                            </v-text-field>
                                        </span>
                                   
                                        <v-btn small class="ml-4" @click="cancelAddingHolidayDate">
                                            Annulla
                                        </v-btn>
                                        <v-btn small class="ml-2" @click="addHolidayDate" :disabled="!addingDateValue || !addingDateName.trim()">
                                            Inserisci
                                        </v-btn>
                                    </div>
                                   
                                    
                                </div>
                            </v-container>
                        </v-row>
                    </v-container>
                </v-form>
            </v-card-text>

            <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
                color="green accent-2"
                class="px-4"
                @click="saveMethod"
                :disabled="addingDate || !yearPicker"
            >
                <v-icon left>
                    mdi-check
                </v-icon>
                Salva
            </v-btn>
            
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>
<script>
const axios = require('axios');

import { DateTime } from 'luxon';

export default {
    data: function() {
        return {
            updatedHolidayDates: [],
            yearPicker: '',
            yearPickerMenu: false,
            activePicker: null,
            holidayHeaders: [
                { text: 'Data', align: 'start', value: 'date' },
                { text: 'Nome', align: 'start', value: 'name' },
                { value: 'actions', align: 'end'}
            ],
            addingDate: false,
            addingDateMenu: false,
            addingDateValue: '',
            addingDateName: '',
        }
    },
    props: {
        editedYearHolidays: {
            type: Object,
            default: function() {
                return {};
            }
        },
        show: {
            type: Boolean,
            required: true
        },
    },
    methods: {
        cancelAddingHolidayDate: function() {
            this.addingDate = false;
            this.addingDateMenu = false;
            this.addingDateValue = '';
            this.addingDateName = '';
        },
        addHolidayDate: function() {
           if(this.addingDateName && this.addingDateValue) {
               let updateIndex = this.updatedHolidayDates.findIndex(hd => hd.date.substr(0, 10) == this.addingDateValue.substr(0, 10));
               if(updateIndex >= 0) {
                    this.updatedHolidayDates[updateIndex].name = this.addingDateName;
               } else {
                    let insertIndex = this.updatedHolidayDates.findIndex(hd => DateTime.fromISO(hd.date) >= DateTime.fromISO(this.addingDateValue)) || this.updatedHolidayDates.length;
                    this.updatedHolidayDates.splice(insertIndex, 0, {date: this.addingDateValue.substring(0, 10), name: this.addingDateName});
               }
               
               this.addingDate = false;
           } 
        },
        editDate: function(index) {
            let editingDate = this.updatedHolidayDates[index];
            this.addingDateValue = editingDate.date;
            this.addingDateName = editingDate.name;
            this.addingDate = true;
        },
        cancelMethod: function() {
            this.$refs.form.reset();
            this.$emit('dialog-close');
            this.updatedHolidayDates = [];
            this.yearPicker = '';
            this.cancelAddingHolidayDate();
        },
        saveMethod: function() {
            this.$emit('save-local-year-holidays', {year: parseInt(this.yearPicker.substring(0, 4)), holidayDates: this.updatedHolidayDates});
            this.cancelMethod();
        },
        setDefaultDates: function() {
            axios.get(
                "https://date.nager.at/api/v3/PublicHolidays/" + this.displayYearPicker + "/IT",
                {}
            ).then(response => {
                // if(response.status < 300 && response.status >= 200 && response.data) {
                //     let dates = response.data;
                // }
                let holidayDates = response.data.map(hd => {
                    return {
                        date: hd.date,
                        name: hd.localName
                    }
                })
                this.updatedHolidayDates = holidayDates;
            }).catch(function(error) {
            // error response flow
            })
        },
        saveYear: function(year) {
            this.yearPicker =  new Date(year, 0, 2).toISOString().substr(0, 10);

            // Reset activePicker to type YEAR
            if(this.$refs.yearPicker) {
                this.$refs.yearPicker.internalActivePicker = 'YEAR';
            }

            // Close the menu/datepicker
            this.yearPickerMenu = false;
        },
        formatDate: function(isoDate) {
            return DateTime.fromISO(isoDate).setLocale('it').toFormat('cccc d LLLL')
        }
    },
    watch: {
        editedYearHolidays: function(val) {
            if(val) {
                this.updatedHolidayDates = JSON.parse(JSON.stringify(val.holidayDates || []));
                if(val.year)
                    this.yearPicker =  val.year.toString() + '-01-01';
            }  
        },
        yearPicker: function(val) {
            if(val) {
                this.$emit('selected-year-change', parseInt(val.substr(0, 4)));
            }
        },
        yearPickerMenu: function(val) {
            val && setTimeout(() => (this.activePicker = 'YEAR'));
        }
    },
    computed: {
        formTitle: function() {
           // return 'Modifica Festività ' + this.editedYearHolidays.year;
           return 'Modifica Festività';
        },
        currentYear: function() {
            return new Date().getFullYear();
        },
        displayYearPicker: {
            get: function() {
                return this.yearPicker.substr(0, 4);
            },
            set: function(val) {

            }
        },
        displayUpdatedHolidayDates: function() {
            return this.updatedHolidayDates.map(uhd => {
                return {
                    name: uhd.name,
                    date:this.formatDate(uhd.date)
                }
            });
        },
        displayAddingDateValue: {
            get: function() {
                if(this.addingDateValue)
                    return this.formatDate(this.addingDateValue);
                return '';
            },
            set: function() {

            }
        }
    },
    mounted: function() {
        // this.setDefaultDates();
    }
}
</script>
